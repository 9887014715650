/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";





// commom css
.main-content {
    font-size: 14px;
  
    ion-col {
      text-align: left;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid transparentize(#ccc, 0.65);
      // background: white;
  
      ion-item {
        width: 100%;
      }
    }
  
    ion-input {
      font-size: 14px;
      --padding-top: 0;
      --padding-bottom: 0;
    }
  
    .header-row {
      background: var(--ion-color-primary);
      color: #fff;
      font-size: 14px;
  
      ion-col {
        background: var(--ion-color-primary);
         
      }

      ion-text{
        padding: 10px;
      }
  
      &.total {
        font-weight: 600;
      }
    }
  }

  
   

  ion-modal {
    --min-height: 700px;
    --max-width: 700px;
  }
  
  

  .icon-style{
     
    font-size: 18px;
  }

  .button-font{
    font-size: 12px;
  }
 
  
.visible-on-mobile-only {
  display: none;
}
  
  
  @media(max-width:768px) {
    ion-modal {
      --min-height: unset;
    }

    .visible-on-mobile-only {
      display: block;
    }
  
    .hidden-on-mobile {
      display: none;
    }
  }
 
table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  // tr:nth-child(even) {
  //   background-color: #dddddd;
  // }
